import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"transition":"dialog-bottom-transition","scrollable":"","width":_vm.width},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VCard,[_c(VToolbar,{staticClass:"font-weight-bold",attrs:{"color":"primary","flat":"","height":"35"}},[_c(VBtn,{attrs:{"color":"primary lighten-3","icon":"","small":"","title":"Voltar"},on:{"click":function($event){return _vm.$emit('fechar')}}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v(" mdi-chevron-left ")])],1),_c(VToolbarTitle,{staticClass:"px-2 white--text subtitle-1"},[_vm._v(" "+_vm._s(_vm.titulo)+" ")]),_c(VSpacer),(_vm.maisOpcoes)?_c(VMenu,{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"mx-0",attrs:{"color":"white","dark":"","small":"","icon":""}},on),[_c(VIcon,[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,false,4264812371)},[(_vm.maisOpcoes)?_c(VList,{staticClass:"pa-0",attrs:{"dense":""}},[_vm._t("maisOpcoes")],2):_vm._e()],1):_vm._e()],1),_c(VCardText,{staticClass:"mt-3 pt-1"},[_vm._t("default")],2),_c(VCardActions,{staticClass:"justify-end"},[_vm._t("botoes"),_c(VBtn,{class:_vm.$vuetify.breakpoint.xsOnly ? 'my-1' : 'mx-1',attrs:{"block":_vm.$vuetify.breakpoint.xsOnly,"color":"error","small":""},on:{"click":function($event){return _vm.$emit('fechar')}}},[_c(VIcon,{attrs:{"left":"","size":"20"}},[_vm._v(" mdi-cancel ")]),_vm._v(" Voltar ")],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }